import Logo from '../../images/500x500Logo.png';
import { FaDiscord, FaTwitter,FaInstagram } from 'react-icons/fa';
import App from '../../../App';
//set logo

const style = {
    logo:`bg-[url('../../images/Logo.png')] md:hidden w-auto left-0 top-0`,
    wrapper: `fixed z-40 top-0 inset-x-0 bg-[#04111d] w-screen px-[1.2rem] py-[0.8rem] flex opacity-[0.9] justify-between` ,
    logoContainer: `flex items-center`,
    logoText: `ml-[0.8rem] text-white font-semibold text-2xl`,
    searchBar: `flex flex-1 mx-[0.8rem] w-max-[520px] items-center rounded-[0.8rem]`,
    headerItem: `flex items-center ml-[0.8rem] text-[#c8cacd] px-4r`,
  
  }

const Navbar = () => {

    return (
        //w-full & flex: setting the prosetion
    <div className=''>
        <div className='hidden md:flex'>
        <nav className={style.wrapper}>
            
            <div className={style.logoContainer}>
                <img src={Logo} height={40} width={40} />
                <div className={style.logoText}>
                    RipHamster
                    </div>
            </div>
            
          
            <div className={style.headerItem}>
                <div className='TitleStyle flex flex-row text-2g'>
                    <div className='mx-4 hover:text-sky-500'>
                        <a href='#gallary'>
                        Our Gallary               
                        </a>
                    </div>

                    <div className='mx-4 hover:text-sky-500'>
                        <a href='#nft'>
                        Hamster NFT
                        </a>
                    </div>

                    <div className='mx-4 hover:text-sky-500'>
                        <a href='#roadmap'>
                        ROADMAP
                        </a>
                    </div>

                    <div className='mx-4 hover:text-sky-500'>
                        <a href='#faq'>
                        FAQ
                        </a>
                    </div>
                </div>
               </div>
               <div className={style.headerItem}>

            
                <a href='https://mobile.twitter.com/i/flow/login'>
                    <div className='mx-2 hover:text-sky-500'>
                    <FaTwitter/>
                    </div>
                </a>
                <a href='https://discord.gg/TKnT6ddvz2'>
                    <div className='mx-2 hover:text-sky-500'>
                    <FaDiscord/>
                    </div>
                </a>
                <a href='https://instagram.com/rip_hamster?utm_medium=copy_link'>
                    <div className='mx-2 hover:text-sky-500'>
                    <FaInstagram/>
                    </div>
                </a>
                <div className='mx-2 hover:text-sky-500'>
                    <a href='#nft'>
                        <button className="rounded-md border-1 md:px-4 py-2 bg-violet-700 flex flex-row mx-2  px-2">
                            <p className=" text-white text-xs md:text-sm">Connect Wallet</p>
                        </button>
                    </a>
                </div>
            </div>
            
            
        </nav>
        </div>


<div className='md:hidden w-auto'>
    <div className={style.wrapper}>
<div className={style.logoContainer}>
                <img src={Logo} height={40} width={40}/>
                <div className={style.logoText}>
                        RipHamster
                </div>

<nav id="site-navigation" aria-label="Menu">

  <label for="toggle-mobile-menu" aria-label="Menu">&#9776;</label>
  <input id="toggle-mobile-menu" type="checkbox" />
 
  <ul className='relative right-0 ' id="main-menu">
    <li><a href='#gallary'>Our Gallary</a></li>
    <li><a href='#nft'>Hamster NFT</a></li>
    <li><a href='#roadmap'>ROADMAP</a></li>
    <li><a href='#faq'>FAQ</a></li>
  </ul>
  
</nav>
</div>
</div>
</div>
</div>  
  
    );
}
export default Navbar;
