import { FaDiscord, FaTwitter, FaInstagram } from 'react-icons/fa';
import Logo2 from '../../images/solana_sol_logo.png';

const Footer = () => {
  return (
    <div className="bg-[#04111d]">
      <div className='flex flex-col grid place-items-center ml-[0.8rem] text-white text-base md:text-2xl p-5'>
        Come and Stay close with our project
        </div>
      <div className="md:flex md:justify-center">
          <a href="https://discord.gg/TKnT6ddvz2">
            <button className=" px-8 py-5 flex flex-row mx-2 hover:hover:bg-black duration-700">
              <FaDiscord size="2em" color="white" />
              <p className="pt-1 text-white pl-2 font-bold">Discord</p>
            </button>
          </a>
          <a href="https://mobile.twitter.com/i/flow/login">
            <button className=" px-8 py-5 flex flex-row mx-2 hover:hover:bg-black duration-700">
              <FaTwitter size="2em" color="white" />
              <p className="pt-1 text-white pl-2 font-bold">Twitter</p>
            </button>
          </a>
          <a href="https://instagram.com/rip_hamster?utm_medium=copy_link">
            <button className=" px-8 py-5 flex flex-row mx-2 hover:hover:bg-black duration-700">
              <FaInstagram size="2em" color="white" />
              <p className="pt-1 text-white pl-2 font-bold">Instagram</p>
            </button>
          </a>
      </div>
      <br/>
      <br/>
      <div className='flex flex-col grid place-items-center  text-white text-base'>
              <p >Copyright © 2022, RipHamster</p>
              <p>All trademarks and copyrights <br/>belong to their respective owners.</p>
              <br/>
              <a href='https://solana.com/zh'>
              <div className='text-xs px-8 py-5 flex flex-row mx-2'>
              <img src={Logo2} height={10} width={10} />
              <p>Built on the  Solana blockchain.</p>
              </div>
              </a>
          </div> 
          <br/>
      </div>
  );
};

export default Footer;
