import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import './client/src/index.css';
import { Footer } from './client/src/components';
import './client/src/time.css';
import Final from './client/src/components/Final';

const QNA = () => {
  const [visible, setVisible] = React.useState(false);
  const [visible1, setVisible1] = React.useState(false);
  const [visible2, setVisible2] = React.useState(false);
  const [visible3, setVisible3] = React.useState(false);
  const [visible4, setVisible4] = React.useState(false);
  const [visible5, setVisible5] = React.useState(false);
  return (
    <div className="display-bg-main">
          <div className=" md:justify-center md:mx-60 ">
      <div className="flex flex-col">
        <div className=" mt-20 text-xl adobe_font">
          <div className="TitleStyle text-xl mb-5" id="faq">
            FREQUENCY ASKED QUESTION:
          </div>
          <div className="mb-10 flex-flex-col">
            <div
              className="h-14 bg-gradient-to-r from-violet-500 to-fuchsia-500 border-2 rounded-md text-white w-full h-auto p-4 border-neutral-300"
              onClick={() => setVisible(!visible)}
            >
              <button className="TitleStyle text-xl hover:text-red-400 ">
                {visible ? 'How To Contact Us?' : 'How To Contact Us?'}
              </button>
              {visible && (
                <div>
                  <br />
                  <p>
                    Contact our support team on discord and twitter, The support
                    will process your request between 24 and 72 hours for an
                    answer by email. Don’t worry, your request will be taken
                    into consideration.
                    <br />
                  </p>
                </div>
              )}
            </div>
            <br />

            <div
              className="h-14 bg-gradient-to-r from-violet-500 to-fuchsia-500 border-2 rounded-md text-white w-auto h-auto p-4 border-neutral-300"
              onClick={() => setVisible1(!visible1)}
            >
              <button className="TitleStyle text-xl hover:text-red-400 ">
                {visible1 ? 'Social Media?' : 'Social Media?'}
              </button>
              {visible1 && (
                <div>
                  <br />
                  <ul>
                    <li>
                      <a href="https://instagram.com/rip_hamster?utm_medium=copy_link">
                        Website
                      </a>
                    </li>
                    <li>
                      <a href="https://discord.gg/TKnT6ddvz2">Discord</a>
                    </li>
                    <li>
                      <a href="">Twitter</a>
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <br />

            <div
              className="h-14 bg-gradient-to-r from-violet-500 to-fuchsia-500 border-2 rounded-md text-white w-auto h-auto p-4 border-neutral-300 "
              onClick={() => setVisible2(!visible2)}
            >
              <button className="TitleStyle text-xl hover:text-red-400">
                {visible2 ? 'Whitelist?' : 'Whitelist?'}
              </button>
              {visible2 && (
                <div>
                  <br />
                  <p>
                    (The whitelist supply will be only 650 in total, and we will
                    be supplying in stages of whitelist requirement, keep
                    updated with the news of RIP Hamster)
                    <br />
                    <br />- Discord x500
                    <ul className="list-decimal pl-4">
                      <li className="">Create hamster sticker</li>
                      <li>Post your hamster artwork and tweet & ig story</li>
                      <li>Invite 3 friends (compulsory)</li>
                      <li>Level 2 Ranking</li>
                    </ul>
                    <br />
                    <p>- Instagram x150</p>
                    <ul className="list-decimal pl-4">
                      <li>
                        Follow RIP Hamster page, Comment and Tag 3 Friends,
                        Repost the post on story and don’t forgot to tag RIP
                        Hamster
                      </li>
                    </ul>
                  </p>
                </div>
              )}
            </div>
            <br />

            <div
              className="h-14 bg-gradient-to-r from-violet-500 to-fuchsia-500 border-2 rounded-md text-white w-auto h-auto p-4 border-neutral-300"
              onClick={() => setVisible3(!visible3)}
            >
              <button className="TitleStyle text-xl hover:text-red-400">
                {visible3 ? 'Giveaway?' : 'Giveaway?'}
              </button>
              {visible3 && (
                <div>
                  <br />
                  <p>
                  We will host at least 2 rounds of giveaways to excite and reward our supporters after public minting, using nfts and digital red pocket(sol) as part of our show of appreciation towards their investment in the community.<br/>The rules will be announced in discord, please stay tuned at our website, discord and instagram.
                  </p>
                </div>
              )}
            </div>
            <br />

            <div
              className="h-14 bg-gradient-to-r from-violet-500 to-fuchsia-500 border-2 rounded-md text-white w-auto h-auto p-4 border-neutral-300"
              onClick={() => setVisible4(!visible4)}
            >
              <button className="TitleStyle text-xl hover:text-red-400">
                {visible4
                  ? 'What Makes This NFT Project Different?'
                  : 'What Makes This NFT Project Different?'}
              </button>
              {visible4 && (
                <div>
                  <br />
                  <p>
                  Is this just another project?
                  <br />
                  <br />
                  We have a unique roadmap, with a strong team behind it to execute it.
                  <br />
                  <br />
                  But everyone says that, so why are we different?
                  <br />
                  <br />
                  Firstly our team is composed of a great artist, one of the best tech devs in TradFi and three big advocates of crypto’s and especially $SOL ’s future. We are doing everything in-house, from learning how to operate our own Discord server to running our own mint.
                  <br />
                  <br />
                  Secondly, we will be bridging the metaverse with reality. Of course, our roadmap includes secondary marketplaces, and the post-mint metaverse community.
                    <br />
                  </p>
                </div>
              )}
            </div>
            <br />

            <div
              className="h-14 bg-gradient-to-r from-violet-500 to-fuchsia-500 border-2 rounded-md text-white w-auto h-auto p-4 border-neutral-300"
              onClick={() => setVisible5(!visible5)}
            >
              <button className="TitleStyle text-xl hover:text-red-400">
                {visible5 ? 'What Do We Do?' : 'What Do We Do?'}
              </button>
              {visible5 && (
                <div>
                  <br />
                  <p>
                    We have a unique charity roadmap incoming, with a strong
                    platform and team behind it to execute it. Will maximize the
                    benefit to the community base on Hong Kong to worldwide’s
                    animals and humans who need direct and instant funds and
                    technical assistance.
                    <br />
                  </p>
                </div>
              )}
            </div>
            <br />
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Final />
    <QNA />
    <Footer />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
