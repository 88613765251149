import React, { useEffect } from 'react';
import hamster from '../../images/track-revurse.gif';
import track from '../../images/track.gif';
import App from '../../../App';


const Display=()=> {


    
    return (

        <div className='display-bg-main'>
        
            <div alt='main' className='md:flex md:justify-center md:mx-60'>

                <div className='flex flex-col'>
            
            <div className=''>
                
                <div className='flex flex-col ' id="nft">

                <div className='flex flex-col grid place-items-center '>
                    <div><img src={hamster} className="w-full"/></div>
                    <div><img src={track} className="w-full"/></div>
                    
                </div>


                <div className='md:w-auto md:h-auto px-6 adobe_font'>
                    <div className='font-bold mb-10 grid justify-items-center mt-10 'id="story">
                        <div className='border-2 rounded-lg bg-gradient-to-l from-blue-400 to-violet-500 '><App/></div>
                        </div>
                        <div className='md:text-xl texl:xs mt-10 mb-10 adobe_font font-bold'>
                        <div className='text-center'>
                            <div>Buy a hamster NFT project to support abandoned animals.</div><br/>
                            <div>If the mint (first offering) is sold out,don't worry you can still buy a RIP Hamster on the secondary market.</div>
                        </div>
                        </div> 
                        <br/>       
                    <p className='md:text-xl text-xs mb-10 font-bold flex justify-center'>Our Official Exchange :</p>
                    <ol className='list-disc ml-10 text-xl font-bold  mt-10'>
                        <li className='flex justify-center'><a href="#">Magic Eden</a></li>
                    </ol>
                    <br/>
                </div>
                </div>


               
                <div>
                <div className='TitleStyle text-2xl mt-10 nb-5' id='roadmap'>RoadMap:</div>
                <div class="timeline text-white ">
                   
                <div className="col-span-full">
                    <p className="text-2xl font-bold pl-5 pt-5 underline underline-offset-1 Q1">Q1:</p>
                   <div data-aos="fade-right" class="text-white container left">
                        <div class="content color1">
                    <p className='font-bold text-xl'>001 Hamster Come Alive</p><br/>
                    <p>The luckiest members of the community will be able to mint 2000 RIP Hamsters, date to be confirmed. There will be a whitelist pre-mint for our early and Hamster supporters of Twitter and Discord, Join our lovely community to receive more details.</p>
                        </div>
                    
                    </div>
                </div>
                    <div data-aos="fade-left" class="text-white container right">
                        <div class="content color1">
                        <p className='font-bold text-xl'>002 Hamster Is Tradeable</p><br/>
                        <p>There are 2000 RIP Hamsters which will be listed on Magic Eden, each one is unique and meaningful. Members get their own hamster if they missed out on the launch. Discord verification and the Hamster Directory will be built, in order to facilitate the secondary market of RIP Hamster. Verify your Twitter and Instagram and show off your exclusive membership to the Hamster Club.</p>
                        </div>
                    </div>
                    <div data-aos="fade-right" class="text-white container left">
                        <div class="content color1">
                        <p className='font-bold text-xl'>003 “DAO” Your Hamster For Charity</p><br/>
                        <p>All future royalties will go into the Charity Fund, of which members can vote on how to spend the funds and decide on which local charity group requires financial support. RIP Hamster is our first launch for local charity in Hong Kong. Hamster holders can anticipate even more lovely charity projects in future.</p>
                        </div>
                    </div>
                    <div data-aos="fade-left" class="text-white container right">
                        <div class="content color1">
                        <p className='font-bold text-xl'>004 Hamster BuyBack</p><br/>
                <p>We use royalties to buy back the floor hamsters from exchanges. Let’s bring our hamsters to the moon!  </p>
                        </div>
                    </div>
                    <div>
                    <p className="text-2xl font-bold pl-5 pt-5 underline underline-offset-1 Q1">Q2:</p>
                    <div data-aos="fade-right" class="text-white container left">
                        <div class="content color1">
                        <p className='font-bold text-xl'>005  Sandbox - Moments Museum of HK</p><br/>
                        <p>With the creator's fees, a virtual museum will pop up in the sandbox metaverse, providing a safe place for all members of the charity community to meet and hang out.  A particular section of the museum will be dedicated to the hamster's memory showcasing our RIP hamster collection and more. Stay tuned for the “Moments Museum Of HK” in the Sandbox metaverse.</p>
                        </div>
                    </div>
                    </div>
                    <div data-aos="fade-right" class="text-white container right">
                        <div class="content color1">
                        <p className='font-bold text-xl'>007 Whitepaper </p><br/>
                        <p>RIP Hamster is only one of its kind based on charity. We will issue a white paper on building a platform based on sustainable charity and memories under the Hong Kong theme.</p>
                        </div>
                    </div>
                    <div data-aos="fade-left" class="text-white container left">
                        <div class="content color1">
                        <p className='font-bold text-xl'>008 Airdrops & Giveaway</p><br/>
                        <p>Future airdrops, giveaway collaborations with celebrities and other NFT projects are already in the pipeline.</p>
                        </div>
                    </div>
                    <div data-aos="fade-left" class="text-white container right">
                        <div class="content color1">
                        <p className='font-bold text-xl'>009 Further</p><br/>
                        <p>We will be constantly adding RIP Hamsters’ owners benefits.We have even more exciting announcements coming up.Stay tuned.</p>
                        </div>
                    </div>
    
                </div>
                </div>

                <div className='flex flex-col grid place-items-center'>
                    <div><img src={hamster}/></div>
                    <div><img src={track}/></div>
                    
                </div>

                </div>
            </div>
        </div>
    </div>
    );
  }
  
  export default Display;