import { Navbar,Main,Display} from "./"
import React, { useEffect } from "react";
import '../time.css'
import photo1 from '../../images/1.gif';
import photo2 from '../../images/2.gif';
import photo7 from '../../images/3.gif';
import photo4 from '../../images/6.gif';
import photo5 from '../../images/5.gif';
import photo6 from '../../images/4.gif';


const Final=()=> {

  return (
    <div className="min-h-screen ">

      <div className="py-8 bg-[#04111d] ">
        <Navbar/>
      </div>
      <div className="gradient-bg-main">
        <Main/>
      </div>


      <div class="final-bg-main" id="gallary">
        <div className='mt-10 md:mx-60'>
          <div className='TitleStyle text-blue-300 text-2xl mt-10 nb-5' id='store'>Our Gallary:</div><br/>
            
            <div className="grid grid-cols-3 justify-items-center ">
            <div>
                <a href='https://magiceden.io/'>
                <img src={photo1} alt='product' className='lg:w-48 lg:h-48 w-32 h-32 2xl:w-60 2xl:h-60  hover:scale-125' />
                </a>
              </div>
              <div>
                <a href='https://magiceden.io/'>
                <img src={photo2} alt='product' className='lg:w-48 lg:h-48 w-32 h-32 2xl:w-60 2xl:h-60  hover:scale-125' />
                </a>
                
              </div>
              <div>
                <a href='https://magiceden.io/'>
                <img src={photo7} alt='product' className='lg:w-48 lg:h-48 w-32 h-32 2xl:w-60 2xl:h-60  hover:scale-125' />
                </a>
                
              </div>
              <div>
                <a href='https://magiceden.io/'>
                <img src={photo4} alt='product' className='lg:w-48 lg:h-48 w-32 h-32 2xl:w-60 2xl:h-60  hover:scale-125' />
                </a>
                
              </div>
              <div>
                <a href='https://magiceden.io/'>
                <img src={photo5} alt='product' className='lg:w-48 lg:h-48 w-32 h-32 2xl:w-60 2xl:h-60  hover:scale-125' />
                </a>
                
              </div>
              <div>
                <a href='https://magiceden.io/'>
                <img src={photo6} alt='product' className='lg:w-48 lg:h-48 w-32 h-32 2xl:w-60 2xl:h-60  hover:scale-125' />
                </a>
                
              </div>
            </div>
            <div className="text-blue-300 md:text-xl texl:xs mt-20 mb-10 adobe_font font-bold ">
                <p className="">HK Charity to explore the upcoming launchpad for NFTs, and more importantly to help the Hamster that has been abandoned.</p><br/>

                <p>A community wallet funded from secondary market sales will be used to promote the collection, events, and to facilitate buyback of RIP Hamsters from the secondary market, to reward long term holders. Initial sales of the NFTs will also be used to fund the running and set up of a virtual museum in the sandbox metaverse.</p><br/>

                <p>With a unique vision and roadmap, this is your chance to be part of a game-changing NFT community at the forefront of Solana and metaverse.</p><br/>

                <p>Buy a hamster NFT project to support abandoned animals.</p><br/>

                <p>If the mint (first offering) is sold out, don't worry you can still buy a RIP Hamster on the secondary market.</p>

              </div>
        </div>
        </div>
        <div className=''>      
        <Display/>
                    
        </div>

      </div>
      

  );
}

export default Final;
