import background from '../../images/1440x1050nanner.png';

const style = {
  logoContainer: `bg-fixed flex justify-center`,
}

const Main=()=> {

    
    return (
      <div className="main snap-proximity snap-y snap-center">
        <div className="snap-proximity snap-y">
          <div className="snap-center">
            <div className={style.logoContainer}>
              <img src={background} height={40} width={1000}/>
            </div>
          </div>
        </div>
      </div>
    
    );
  }
  
  export default Main;